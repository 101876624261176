export const QUESTION_TYPE = {
  CHOOSE_DATE: 'choose-date',
  CHOOSE_MULTIPLE: 'choose-multiple',
  MULTIPLE_OPEN_ANSWERS: 'multiple-open-answers',
  CHOOSE_ONE: 'choose-one',
  CHOOSE_PAYMENT: 'choose-payment',
  CHOOSE_PRODUCT: 'choose-product',
  CODES_QUESTION: 'codes-question',
  DROPDOWN: 'dropdown',
  EMAIL: 'email',
  INFO: 'info',
  LOCATION: 'location',
  MATRIX: 'matrix',
  NUMERIC: 'numeric',
  OPEN_ANSWER: 'open-answer',
  PAIR: 'paired-questions',
  MAX_DIFF: 'max-diff',
  PAYPAL_EMAIL: 'paypal-email',
  PROFILE_CHART: 'profile',
  TETRAD_COMPLETION: 'tetrad-completion-screen',
  SLIDER: 'slider',
  SELECT_AND_JUSTIFY: 'select-and-justify',
  SHOW_PRODUCT_SCREEN: 'show-product-screen',
  TASTER_NAME: 'taster-name',
  UPLOAD: 'upload-picture',
  UPLOAD_VIDEO: 'upload-video',
  VERTICAL_RATING: 'vertical-rating',
  TIME_STAMP: 'time-stamp',
  TRIANGLE_SET: 'triangle-test',
  TWO_AFC: 'two-afc',
  MOBILE_PAYMENT: 'mobile-payment',
  RANKING: 'ranking',
  TETRAD_TEST: 'tetrad-test',
  TIME_INTENSITY_SLIDER: 'time-intensity-slider',
  IMPLICIT_CALIBRATION: 'implicit-calibration',
  EXPLICIT_CALIBRATION: 'explicit-calibration',
  CONJOINT: 'conjoint'
}
