import action from '@/utils/currency'
import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'survey',
  initialState: {
    callToRefreshText: '',
    showCustomTitle: false,
    showNumbersPrompt: false,
    showAttributeScreen: false,
    isInfoScreen: false,
    enrollment: null,
    question: null,
    questionError: null,
    reviewParams: {},
    reviewQuestions: [],
    renderFollowUp: false,
    pipeAnswer: []
  },
  reducers: {
    setCallToRefreshText: (state, action) => {
      state.callToRefreshText = action.payload
    },
    setShowCustomTitle: (state, action) => {
      state.showCustomTitle = action.payload
    },
    setShowAttributeScreen: (state, action) => {
      state.showAttributeScreen = action.payload
    },
    setIsInfoScreen: (state, action) => {
      state.isInfoScreen = action.payload
    },
    setShowNumbersPrompt: (state, action) => {
      state.showNumbersPrompt = action.payload
    },
    setEnrollment: (state, action) => {
      state.enrollment = action.payload
    },
    setQuestion: (state, action) => {
      state.question = action.payload
    },
    setQuestionError: (state, action) => {
      state.questionError = action.payload
    },
    setAuthorizationType: (state, action) => {
      state.authorizationType = action.payload
    },
    setReviewParams: (state, action) => {
      state.reviewParams = action.payload
    },
    setReviewQuestions: (state, action) => {
      state.reviewQuestions = action.payload
    },
    setRenderFollowUp: (state, action) => {
      state.renderFollowUp = action.payload
    },
    setPipeAnswer: (state, action) => {
      state.pipeAnswer = action.payload
    }
  }
})

export const {
  setCallToRefreshText,
  setShowCustomTitle,
  setShowNumbersPrompt,
  setShowAttributeScreen,
  setIsInfoScreen,
  setEnrollment,
  setQuestion,
  setQuestionError,
  setAuthorizationType,
  setReviewParams,
  setReviewQuestions,
  setRenderFollowUp,
  setPipeAnswer
} = slice.actions

export default slice.reducer
